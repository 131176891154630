@import 'bootstrap/dist/css/bootstrap.min.css';
@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;400;500;600;700&display=swap');

@font-face {
    font-family: 'Pacifica';
    src: url(../assets/fonts/Pacifica-Condensed.ttf) format('truetype');
  }

  .font-size_large {
    font-size: large;
  }

.pacifica {
    font-family: Pacifica;
}

.rajdhani {
    color: #ede7f6 !important;
    font-family: 'Rajdhani', sans-serif !important;
    font-weight: 500 !important;
}

.rajdhani-semibold {
    color: #ede7f6 !important;
    font-family: 'Rajdhani', sans-serif !important;
    font-weight: 600 !important;
}

.rajdhani-bold {
    color: #ede7f6;
    font-family: 'Rajdhani', sans-serif;
    font-weight: 700;
}

.subheader__text {
  color: #fff;
  text-shadow:
 -1px -1px 0 #000,  
  1px -1px 0 #000,
  -1px 1px 0 #000,
   1px 1px 0 #000;
}

.home-header__text {
    text-align: center;
    color: #fff;
    text-shadow:
        0 0 7px #fff,
        0 0 10px #fff,
        0 0 21px #fff,
        0 0 42px rgb(221, 0, 255),
        0 0 82px rgb(221, 0, 255),
        0 0 92px rgb(221, 0, 255),
        0 0 102px rgb(221, 0, 255),
        0 0 151px rgb(221, 0, 255),
        -1px -1px 0 #000,  
        1px -1px 0 #000,
        -1px 1px 0 #000,
         1px 1px 0 #000;
  }

  .home-subheader__text {
    text-align: center;
    color: #fff;
    text-shadow:
   -1px -1px 0 #000,  
    1px -1px 0 #000,
    -1px 1px 0 #000,
     1px 1px 0 #000;
  }

  .home-inquiries {
    margin: 10px 0 10px 0;
  }

  .bg-custom__dark {
    background-color: #220046;
  }

  .bg-custom__med {
    background-color: #360959;
  }

  .bg-custom__med-light {
    background-color: rgb(85, 10, 143);
  }

  .bg-custom__light {
    background-color: rgb(218,112,214);
  }

  /* .bg-gradient_gallery {
    background-image: radial-gradient(circle, #220046, #360959, rgb(85, 10, 143));
  } */
  
  .bg-gradient_gallery {
    background-image: radial-gradient(circle, rgb(85, 10, 143), #360959, #220046);
  }

  .background-color_transparent {
    background-color: rgba(85, 10, 143, 0.01) !important;
  }

  .btn-border-none {
    border: none;
  }

  .btn-dark:hover {
    color: #f9f7f4;
    background-color: rgb(218,112,214);
    border-color: #5b288e;
  }

  .footer-list {
    list-style: none;
    color: #fff;
    padding: 0;
    list-style-type: none;
  }

  .footer-content {
    font-size: small;
  }

  a {
    text-decoration: none !important;
    color: #ede7f6;
  }

  .underline-link {
    text-decoration: underline !important;
  }

  .img-height_75vh {
    max-height: 75vh !important;
  }
  .img-height_50vh {
    max-height: 50vh !important;
  }

  .shop-card_margin {
    margin-bottom: 1rem !important;
  }

  .edu-promo-pic__height-fix {
    height: 18rem !important;
  }

  